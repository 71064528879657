import React, { useState, useContext } from 'react';
import { navigate } from 'gatsby';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import PhoneTwoToneIcon from '@material-ui/icons/PhoneTwoTone';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { notNull, isNull } from 'utils/text';
import { CircularProgressContext } from 'components/CircularProgressOverlay';
import { logout } from 'services/loginService';
import { callcenterService, setCallcenterUser } from 'services/callcenterService';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        Pergo
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/featured/?callcenter)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

export default function LoginPage() {
  const classes = useStyles();
  const [formValues, setFormValues] = useState({
    email: '',
    password: '',
  });
  const [formErrors, setFormErrors] = useState(null);
  const [isError, setError] = useState(false);
  const { showCircularProgress, hideCircularProgress } = useContext(CircularProgressContext);

  const handleChange = (id, value) => {
    setFormValues({
      ...formValues,
      [id]: value
    });
  }

  const checkValueValidate = (id, value) => {
    let valid = notNull(value) && value.length > 0;
    let error = null;

    if (!valid) {
      error = 'Please input value';
    } else {
      if (id === 'email') {
        valid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        error = valid ? null : 'Please input valid Email';
      }
    }

    return error;
  };

  const checkStepValidate = (keys) => {
    let errors = {};
    keys.forEach(id => {
      const error = checkValueValidate(id, notNull(formValues) ? formValues[id] : null);
      errors[id] = error;
    })
    setFormErrors(errors);

    const stepValid = Object.keys(errors).filter(item => errors[item] !== null).length;
    return stepValid === 0;
  };

  const handleSubmit = async () => {
    const keys = ['email', 'password'];
    if (!checkStepValidate(keys)) {
      return;
    }

    showCircularProgress('Please wait');
    callcenterService.login({ email: formValues.email, password: formValues.password }).then(({ data }) => {
      hideCircularProgress();
      if (data.status === 'SUCCESS' && data.data.num_rows > 0) {
        console.log('SUCCESSFULLY LOGGEDIN');
        setCallcenterUser(data.data.data);
        logout();
        navigate('/callcenter');
      } else {
        setError(true);
      }
    });
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <PhoneTwoToneIcon />
          </Avatar>
          <Typography component="h1" variant="h5" className="mb-3">
            Call Center Sign in
          </Typography>
          <div className={classes.form}>
            {isError && (
              <Typography component="h6" color="error">
                * Invalid Email or Password
              </Typography>
            )}

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email"
              autoComplete="email"
              value={formValues.email}
              onChange={e => handleChange('email', e.target.value)}
              error={notNull(formErrors?.email)}
              helperText={formErrors?.email}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              autoComplete="current-password"
              value={formValues.password}
              onChange={e => handleChange('password', e.target.value)}
              error={notNull(formErrors?.password)}
              helperText={formErrors?.password}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="mt-4 mb-3 py-3"
              onClick={handleSubmit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/callcenter/password" variant="body2">
                  Retrieve Password
                </Link>
              </Grid>
              <Grid item>
                <Link href="/callcenter/signup" variant="body2">
                  Don't have an account? Sign Up
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </div>
          <Link href="/login" variant="body2" style={{ marginTop: 30 }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className="mt-4 mb-3 py-3"
            >
              <span className="btn-wrapper--label">Go to Portal</span>
            </Button>
          </Link>
        </div>
      </Grid>
    </Grid>
  );
}
